<template>
  <div>
    <a-button-group>
      <a-button
        v-if="item.approval_order_item.type === 'purchase'"
        type="default"
        size="small"
        @click="jumpPurchaseOrderDetail(item)"
      >
        审阅
      </a-button>
      <a-button
        v-if="item.approval_order_item.type === 'purchase_return'"
        type="default"
        size="small"
        @click="jumpPurchaseReturnOrderDetail(item)"
      >
        审阅
      </a-button>
      <a-button
        v-if="item.approval_order_item.type === 'sales'"
        type="default"
        size="small"
        @click="jumpSalesOrderDetail(item)"
      >
        审阅
      </a-button>
      <a-button
        v-if="item.approval_order_item.type === 'inspection'"
        type="default"
        size="small"
        @click="jumpInspectionReportDetail(item)"
      >
        审阅
      </a-button>
      <a-button type="primary" size="small" @click="approveModalVisible = true">通过</a-button>
      <a-button type="danger" size="small" @click="rejectModalVisible = true">拒绝</a-button>
    </a-button-group>

    <ApproveModal v-model="approveModalVisible" :item="item" @confirm="update" @cancel="approveModalVisible = false" />
    <RejectModal v-model="rejectModalVisible" :item="item" @confirm="update" @cancel="rejectModalVisible = false" />
  </div>
</template>

<script>
export default {
  components: {
    ApproveModal: () => import("./ApproveModal"),
    RejectModal: () => import("./RejectModal"),
  },
  props: ["item"],
  data() {
    return {
      approveModalVisible: false,
      rejectModalVisible: false,
    };
  },
  methods: {
    update() {
      this.$emit("update");
    },
    jumpPurchaseOrderDetail(item) {
      this.$router.push({ path: "/purchasing/purchase_record_detail", query: { id: item.approval_order_item.purchase_order } });
    },
    jumpPurchaseReturnOrderDetail(item) {
      this.$router.push({
        path: "/purchasing/return_record_detail",
        query: { id: item.approval_order_item.purchase_return_order },
      });
    },
    jumpSalesOrderDetail(item) {
      this.$router.push({ path: "/approval/approval_sales_detail", query: { id: item.approval_order_item.sales_order } });
    },
    jumpInspectionReportDetail(item) {
      this.$router.push({
        path: "/inspection/inspection_report_detail",
        query: { id: item.approval_order_item.inspection_report },
      });
    },
  },
};
</script>

<style scoped></style>
